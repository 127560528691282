<template>
  <b-card class="box-shadow-0 mb-0">
    <b-card-title class="text-primary">
      Other Info
    </b-card-title>
    <b-card-sub-title class="mb-1">
      Please provide the necessary information below.
    </b-card-sub-title>
    <validation-observer ref="tour-info">
      <b-row class="mb-1">
        <b-col md="12">
          <b-form-group
            label="Category"
            label-for="category"
          >
            <v-select
              id="category"
              v-model="OtherInfo.category"
              :options="categoryList"
              label="name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Pickup Locations"
            label-for="pick_up"
          >
            <b-input-group>
              <b-form-input
                id="pick_up"
                v-model="OtherInfo.pick_up"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Vendor"
            label-for="vendor"
          >
            <v-select
              id="vendor"
              v-model="OtherInfo.vendor"
              :options="vendorList"
              label="name"
              class="w-100"
              @search="(search) => searchVendor(search)"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tour Timing"
            label-for="tour_timing"
          >
            <b-input-group>
              <b-form-input
                id="tour_timing"
                v-model="OtherInfo.tour_timing"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Start Timing"
            label-for="starting_time"
          >
            <b-input-group>
              <cleave
                id="starting_time"
                v-model="OtherInfo.starting_time"
                class="form-control"
                :raw="false"
                :options="options.time"
                placeholder="hh:mm"
              />
              <b-input-group-append>
                <b-form-timepicker
                  id="starting_time"
                  v-model="OtherInfo.starting_time"
                  button-variant="outline-primary"
                  locale="en"
                  size="sm"
                  button-only
                  right
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <quill-editor
              ref="description"
              v-model="OtherInfo.description"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Requirements"
            label-for="requirements"
          >
            <quill-editor
              ref="requirements"
              v-model="OtherInfo.requirements"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Travel Tips"
            label-for="travel_tips"
          >
            <quill-editor
              ref="travel_tips"
              v-model="OtherInfo.travel_tips"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Option Type"
            label-for="option_type"
          >
            <validation-provider
              #default="{ errors }"
              name="option type"
              rules="required"
            >
              <v-select
                id="option_type"
                v-model="OtherInfo.option_type"
                :options="otherType"
                :reduce="options => options.value"
                label="name"
                class="w-100"
              />
              <small
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Priority"
            label-for="priority"
          >
            <validation-provider
              #default="{ errors }"
              name="priority"
              rules="required"
            >
              <b-input-group>
                <b-form-input
                  id="priority"
                  v-model.number="OtherInfo.priority"
                  type="number"
                />
              </b-input-group>
              <small
                class="text-danger"
              >
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Seasonal Date"
            label-for="preferred_season_date"
          >
            <b-form-datepicker
              id="preferred_season_date"
              v-model="OtherInfo.preferred_season_date"
              :date-format-options="{
                year: 'numeric', month: 'short', day: '2-digit', weekday: 'short'
              }"
              reset-button
              @input="(val) => OtherInfo.preferred_season_date = val || null"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Advance Booking Restriction"
            label-for="booking-restriction"
          >
            <b-input-group append="Day(s)">
              <b-form-input
                id="booking-restriction"
                v-model.number="OtherInfo.restrict_booking_after"
                type="number"
                min="0"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <h6 class="text-bold mb-1 text-primary">
            Pax Required
          </h6>
          <b-form-group>
            <b-form-checkbox
              v-model="OtherInfo.is_pax_required"
              name="pax_required"
              switch
              class="switch-box"
            >
              <span class="switch-icon-left">
                YES
              </span>
              <span class="switch-icon-right">
                NO
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <h6 class="text-bold mb-1 text-primary">
            Is Bookable ?
          </h6>
          <b-form-group>
            <b-form-checkbox
              v-model="OtherInfo.is_bookable"
              name="is_bookable"
              switch
              class="switch-box"
            >
              <span class="switch-icon-left">
                YES
              </span>
              <span class="switch-icon-right">
                NO
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <h6 class="text-bold mb-1 text-primary">
            Is Custom Package ?
          </h6>
          <b-form-group>
            <b-form-checkbox
              v-model="OtherInfo.is_custom_package"
              name="is_custom_package"
              switch
              class="switch-box"
            >
              <span class="switch-icon-left">
                YES
              </span>
              <span class="switch-icon-right">
                NO
              </span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="btn-wrapper text-right">
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="validateForm()"
      >
        <b-spinner
          v-if="isLoading"
          small
        />
        <span>Update</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'OtherInfo',
  components: {
    quillEditor,
    ValidationObserver,
    ValidationProvider,
    Cleave,
  },
  mixins: [helper],
  props: {
    tourData: {
      type: Object,
    },
    tourId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isLoading: false,
      categoryList: [],
      vendorList: [],
      required,
      OtherInfo: {
        category: '',
        tour_timing: '',
        starting_time: '',
        priority: null,
        address: '',
        description: '',
        pick_up: '',
        requirements: '',
        travel_tips: '',
        option_type: null,
        vendor: '',
        is_pax_required: false,
        is_bookable: false,
        is_custom_package: false,
        preferred_season_date: null,
        restrict_booking_after: 0,
      },
      otherType: [
        { value: 0, name: 'Single' },
        { value: 1, name: 'Multiple' },
      ],
      vendorQuery: '',
      options: {
        time: {
          time: true,
          timePattern: ['h', 'm'],
        },
      },
    }
  },
  mounted() {
    this.getCategoryList()
    this.getVendorList()
    if (this.tourId && this.$route.params.id !== 'new') {
      this.getOtherInfo()
    }
  },
  methods: {
    getCategoryList() {
      api.getData('tour/admin_services/category_list/', true).then(response => {
        if (response.data.status) {
          this.categoryList = response.data.result.results
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      })
    },
    searchVendor(query) {
      this.vendorQuery = query
      this.debounceSearch(0, this.getVendorList)
    },
    getVendorList() {
      const query = this.vendorQuery ? `&search=${this.vendorQuery}` : ''
      api.getData(`tour/admin_services/vendor_list/?offset=0${query}`, true).then(response => {
        if (response.data.status) {
          this.vendorList = response.data.result.results
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong')
      })
    },
    getOtherInfo() {
      this.OtherInfo = {
        category: this.tourData.category,
        tour_timing: this.tourData.tour_timing,
        starting_time: this.tourData.starting_time,
        priority: this.tourData.priority,
        address: '',
        description: this.tourData.description,
        pick_up: this.tourData.pick_up,
        requirements: this.tourData.requirements,
        travel_tips: this.tourData.travel_tips,
        option_type: this.tourData.option_type,
        vendor: this.tourData.vendor,
        is_pax_required: this.tourData.is_pax_required === null ? false : this.tourData.is_pax_required,
        is_bookable: this.tourData.is_bookable,
        preferred_season_date: this.tourData.preferred_season_date,
        is_custom_package: this.tourData.is_custom_package,
        restrict_booking_after: this.tourData.restrict_booking_after,
      }
    },
    validateForm() {
      this.$refs['tour-info'].validate().then(success => {
        if (success) {
          this.callTourApi()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    callTourApi() {
      this.isLoading = true
      const formData = JSON.parse(JSON.stringify(this.OtherInfo))
      formData.category = this.OtherInfo.category && this.OtherInfo.category.id ? this.OtherInfo.category.id : this.OtherInfo.category
      formData.vendor = this.OtherInfo.vendor && this.OtherInfo.vendor.id ? this.OtherInfo.vendor.id : this.OtherInfo.vendor
      api.updateData(`tour/admin_services/tour/${this.tourId}/tour_update/`, true, formData).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Tour extra informations updated successfully')
          this.$emit('updateTourInfo', response.data.result.id)
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
.switch-box {
  .custom-control-label {
    &::before {
      width: 60px;
    }
    .switch-icon-right {
      right: 5px;
    }
    .switch-icon-left {
      left: 10px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(2.5rem);
  }
}
</style>
