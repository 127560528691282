<template>
  <b-card
    class="box-shadow-0 mb-0"
  >
    <b-card-title class="text-primary">
      Tour Location
    </b-card-title>
    <b-card-sub-title class="mb-1">
      Please provide the necessary information below.
    </b-card-sub-title>
    <validation-observer ref="tour-location">
      <b-row class="mb-1">
        <b-col md="6">
          <b-form-group
            label="Region*"
            label-for="region"
          >
            <validation-provider
              #default="{ errors }"
              name="region"
              rules="required"
            >
              <v-select
                id="region"
                v-model="tourLocation.region"
                :options="countries"
                :reduce="option => option.value"
                label="name"
                class="w-100"
                :clearable="false"
                @input="updateOptionList"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-row class="align-items-center">
            <b-col
              md="6"
              sm="10"
            >
              <b-form-group
                label="Location*"
                label-for="location"
              >
                <validation-provider
                  #default="{ errors }"
                  name="location"
                  rules="required"
                >
                  <v-select
                    id="locations"
                    v-model="tourLocation.tour_location"
                    label="name"
                    :options="options"
                    :disabled="isLocationLoading"
                  />
                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!isLocationLoading && !!tourLocation.tour_location"
              md="4"
            >
              <small class="d-block">
                Type - {{ tourLocation.tour_location.type ? tourLocation.tour_location.type : 'N/A' }}
              </small>
              <small class="d-block">
                Latitude - {{ tourLocation.tour_location.latitude ? tourLocation.tour_location.latitude : 'N/A' }}
              </small>
              <small class="d-block">
                Longitude - {{ tourLocation.tour_location.longitude ? tourLocation.tour_location.longitude : 'N/A' }}
              </small>
            </b-col>
            <b-col
              md="2"
              sm="2"
              class="pl-0"
            >
              <b-spinner
                v-if="isLocationLoading"
                variant="primary"
              />
            </b-col>
            <!--            <b-col md="6">
              <div class="mt-1">
                <small class="text-primary">
                  <i class="fa fa-info-circle" /> Create a new location if it isn't listed
                </small>
                <br>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="showLocationModal"
                >
                  Add New Location
                </b-button>
              </div>
            </b-col>-->
            <b-col md="12">
              <b-form-group
                label="Around Locations"
                label-for="around_locations"
              >
                <b-form-textarea
                  id="around_locations"
                  v-model="tourLocation.around_locations"
                  rows="5"
                  no-resize
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Recommended Restaurants"
                label-for="recommended_restaurants_nearby"
              >
                <b-form-textarea
                  id="recommended_restaurants_nearby"
                  v-model="tourLocation.recommended_restaurants_nearby"
                  rows="5"
                  no-resize
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              sm="12"
            >
              <b-form-group
                label="FMA Locations"
                label-for="fma-locations"
              >
                <validation-provider
                  #default="{ errors }"
                  name="fma locations"
                >
                  <v-select
                    id="fma-locations"
                    v-model="tourLocation.fma_locations"
                    label="name"
                    :options="options"
                    multiple
                    :reduce="option => option.id"
                  />
                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="btn-wrapper text-right">
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click="isTourLocationValid()"
        >
          <b-spinner
            v-if="isLoading"
            small
          />
          <span>Update</span>
        </b-button>
      </div>
    </validation-observer>
    <!--    <LocationModal @updateTourlocation="updateTourLocation" />-->
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import LocationModal from '@/views/tour/onboarding/basic-info/LocationModal.vue'
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'

export default {
  name: 'TourLocation',
  components: {
    ValidationProvider,
    ValidationObserver,
    // LocationModal,
  },
  mixins: [helper, tour],
  props: {
    tourData: {
      type: Object,
    },
    tourId: {
      type: [Number, String],
    },
    renderMap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      isLoading: false,
      districtList: [],
      tourLocation: {
        around_locations: '',
        recommended_restaurants_nearby: '',
        region: 'BD',
        tour_location: null,
      },
      mapInitialPosition: [23.8001, 90.4065],
      options: [],
      searchQuery: '',
      isLocationLoading: false,
      countries: [
        {
          name: 'Bangladesh',
          value: 'BD',
        },
        {
          name: 'Pakistan',
          value: 'PK',
        },
      ],
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.getTourLocation()
    } else {
      this.getLocationOptions()
    }
  },
  methods: {
    getTourLocation() {
      this.tourLocation = {
        region: this.tourData.region ? this.tourData.region : 'BD',
        // tour_location: this.tourLocation.tour_location ? this.tourData.tour_location.id : '',
        around_locations: this.tourData.around_locations,
        recommended_restaurants_nearby: this.tourData.recommended_restaurants_nearby,
        fma_locations: this.tourData.fma_locations,
      }
      this.getLocationOptions('', this.tourData.tour_location)
    },
    getCoordinatesFromMap(latitude, longitude) {
      this.tourLocation.lat = latitude
      this.tourLocation.lng = longitude
    },
    getAddressFromMap(address) {
      const districtName = this.districtList.filter(district => (district.district).toLowerCase() === (address.city).toLowerCase())[0]
      if (districtName) {
        this.tourLocation.location = districtName.id
      } else {
        this.tourLocation.location = ''
      }
    },
    callTourApi() {
      this.isLoading = true
      const formData = JSON.parse(JSON.stringify(this.tourLocation))
      formData.fma_locations = formData.fma_locations && formData.fma_locations.length ? formData.fma_locations : []
      formData.tour_location = formData.tour_location && formData.tour_location.id ? formData.tour_location.id : formData.tour_location
      api.updateData(`tour/admin_services/tour/${this.tourId}/tour_update/`, true, formData).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Tour location updated successfully')
          this.$emit('updateTourInfo', response.data.result.id)
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
      })
    },
    isTourLocationValid() {
      this.$refs['tour-location'].validate().then(success => {
        if (success) {
          this.callTourApi()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
    getLocationOptions(query, location) {
      this.isLocationLoading = true
      const searchQuery = query ? `&search=${query}` : ''
      api.getData(`location/admin_services/locations/?region=${this.tourLocation.region}${searchQuery}`, true).then(response => {
        if (response.data.status) {
          if (response.data.result.length) {
            this.options = response.data.result
          }
          if (location) {
            this.$set(this.tourLocation, 'tour_location', location)
          }
        } else {
          this.notificationMessage('danger', 'fas fa-times', 'Error', response.data.error.message)
        }
      }).catch().finally(() => {
        this.isLocationLoading = false
      })
    },
    getSearchQuery(query) {
      this.debounceSearch(0, () => this.getLocationOptions(query))
    },
    updateOptionList() {
      this.tourLocation.tour_location = null
      this.getLocationOptions()
    },
    updateTourLocation(id) {
      this.tourLocation.location.push(id)
    },
    showLocationModal() {
      this.$root.$emit('bv::show::modal', 'location-modal')
    },
  },
}
</script>
