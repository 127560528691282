<template>
  <b-card no-body>
    <form-wizard
      ref="tour-wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="tab"
      back-button-text="Previous"
      next-button-text="Submit"
      finish-button-text="Submit"
      hide-buttons
      @on-change="updateMapRender"
    >
      <tab-content icon="fas fa-torii-gate">
        <TourInfo
          v-if="isTourDataLoaded"
          :tour-data="tourData"
          :tour-id="tourId"
          @updateTourInfo="setTourId"
        />
      </tab-content>
      <tab-content icon="fas fa-map-marked-alt">
        <TourLocation
          v-if="isTourDataLoaded && tourId"
          :tour-data="tourData"
          :render-map="mapRendered"
          :tour-id="tourId"
          @updateTourInfo="goToNextTab"
        />
      </tab-content>
      <tab-content icon="fas fa-file-alt">
        <OtherInfo
          v-if="isTourDataLoaded"
          :tour-data="tourData"
          :tour-id="tourId"
          @updateTourInfo="goNext"
        />
      </tab-content>
    </form-wizard>
    <div
      v-if="tourId && this.$route.params.id !== 'new'"
      class="text-right p-2 border-top"
    >
      <b-button
        variant="outline-primary"
        :to="`/tour-register/step-2/${tourId}`"
      >
        Policies
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import TourInfo from '@/views/tour/onboarding/basic-info/TourInfo.vue'
import TourLocation from '@/views/tour/onboarding/basic-info/TourLocation.vue'
import OtherInfo from '@/views/tour/onboarding/basic-info/OtherInfo.vue'
import tour from '@/mixins/tour'
import helper from '@/mixins/helper'

export default {
  name: 'BasicInfo',
  components: {
    FormWizard,
    TabContent,
    TourInfo,
    TourLocation,
    OtherInfo,
  },
  mixins: [tour, helper],
  data() {
    return {
      tourData: {},
      tourId: '',
      isTourDataLoaded: false,
      mapRendered: false,
    }
  },
  mounted() {
    if (this.$route.params.id !== 'new') {
      this.tourId = this.$route.params.id
      this.getTourBasicInfo()
      this.$refs['tour-wizard'].activateAll()
    } else {
      this.isTourDataLoaded = true
    }
  },
  methods: {
    getTourBasicInfo() {
      this.getBasicInfo(this.tourId).then(response => {
        if (response.data.status) {
          this.tourData = response.data.result
          this.updateChosenTour(this.tourData)
          this.isTourDataLoaded = true
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          if (response.data.error.code === 400) {
            this.$router.push('/')
          }
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, couldn\'t process your request.')
      }).finally(() => {
        window.scrollTo(0, 0)
      })
    },
    goToNextTab(tourId) {
      this.$refs['tour-wizard'].nextTab()
      this.tourId = tourId
      window.scrollTo(0, 0)
    },
    setTourId(tourId) {
      this.tourId = tourId
      this.goToNextTab(this.tourId)
    },
    updateMapRender(prevIndex, nextIndex) {
      if (nextIndex === 1) {
        this.mapRendered = true
      } else {
        this.mapRendered = false
      }
    },
    goNext(id) {
      this.$router.push(`/tour-register/step-2/${id}`)
    },
  },
}
</script>

<style lang="scss">
.vue-form-wizard {
  .wizard-header {
    padding: 0;
    overflow: hidden;
  }
  &.md {
    .wizard-navigation {
      .wizard-progress-with-circle {
        height: 0;
      }
    }
  }
  .wizard-nav-pills>li>a {
    top: 0;
  }
  .wizard-tab-content {
    padding: 0;
  }
}
</style>
