import api from '@/libs/api'

export default {
  methods: {
    getTourData(id) {
      return api.getData(`tour/admin_services/tour/${id}/`, true)
    },
    getBasicInfo(id) {
      return api.getData(`/tour/admin_services/tour/${id}/basic_info/`, true)
    },
    getAreaSuggestionFromBK(query) {
      return api.getData(`location/map/autocomplete/?search=${query}`, true)
    },
    getReverseGeocoding(lat, long) {
      return api.getData(`location/map/geo_code/?latitude=${lat}&longitude=${long}`)
    },
    updateChosenTour(tourData) {
      this.$store.commit('tour/updateProperty', {
        id: tourData.id,
        name: tourData.name,
        location: tourData.tour_location,
        duration: tourData.duration,
        is_active: tourData.is_active,
        is_bookable: tourData.is_bookable,
        restrict_booking_after: tourData.restrict_booking_after,
        currency_code: tourData.currency_code,
      })
    },
    isTourValid(id, callback) {
      if (Number(id) === Number(this.$store.state.tour.property.id)) {
        callback()
      } else {
        this.$router.push('/')
      }
    },
    changeImageOrder(event, pictures) {
      const { oldIndex, newIndex } = event.moved
      let start
      let end
      if (newIndex > oldIndex) {
        start = 0
        end = newIndex
      } else {
        start = newIndex
        end = pictures.length - 1
      }
      for (let index = start; index <= end; index += 1) {
        const image = pictures[index]
        api.updateData(`tour/admin_services/tour_picture/${image.id}/`, true, { id: image.id, p_index: index + 1 })
      }
    },
    redirectToWeb(id) {
      const link = `${process.env.VUE_APP_WEB}tour/details/?id=${id}`
      window.open(link, '_blank')
    },
  },
}
